import { notification } from "antd";
import { isEqual } from "lodash";
import {
  addValidationRulesToForm,
  getAnswerByKey,
  setQuestion,
} from "modules/framework/components/question/common-methods";
import { USERROLE } from "../../constants";
import { BREADCRUMB_KEYS } from "../../modules/framework/types";
import { StatusType } from "../enums";

export const changePartnerCreatedDateFormat = (data) => {
  if (data) {
    for (const item of data) {
      item.created = `${new Date(item.created).toDateString()}`;
      if (item.children?.length) {
        changePartnerCreatedDateFormat(item.children);
      }
    }
  }
  return data;
};

export const getFilterData = (data, value, filterField) => {
  let result = data.filter((entry) => {
    const comparisionValue = filterField ? entry[filterField] : entry.name;
    return comparisionValue
      .toString()
      .toLowerCase()
      .split(" ")
      .join("")
      .includes(value.toString().toLowerCase().split(" ").join(""));
  });
  if (result.length > 0) {
    return result;
  }
};

export const getActiveModules = (routePaths) => {
  let requiredPaths = {};
  Object.entries(routePaths).forEach((entry) => {
    if (entry[1]?.featureflag !== false) {
      requiredPaths[entry[0]] = entry[1];
    }
  });
  return requiredPaths;
};

export const openNotificationWithIcon = ({
  type,
  message,
  placement = "topRight",
}) => {
  notification[type]({
    description: `${message}`,
    placement,
    duration: 3,
  });
};

export const sortData = (dataKey, moduleData) => {
  if (!["templates", "frameworks"].includes(dataKey)) {
    let cardVersions = moduleData.data.map((element) => element.number);
    cardVersions = cardVersions
      .map((a) => a.replace(/\d+/g, (n) => +n + 100000))
      .sort()
      .map((a) => a.replace(/\d+/g, (n) => +n - 100000));
    let originalCardData = moduleData.data;
    let sortedCardData = moduleData.data.reduce((acc, item, index) => {
      let sortedElement = originalCardData.find(
        (card) => card.number === cardVersions[index]
      );
      return [...acc, sortedElement];
    }, []);
    if (sortedCardData) {
      moduleData.data = sortedCardData;
    }
  }
};

export const getIndexByID = (id, existingComponentData) => {
  return existingComponentData?.findIndex((item) => item.id === id);
};

export const isObjectEqual = (firstObject, secondObject) => {
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
};
// This method only compares elements in array excluding the order
export const isEqualArray = (arr1, arr2) =>
  arr1 === arr2 ||
  (arr1?.length === arr2?.length &&
    !arr1?.some((a) => !arr2?.find((b) => isEqual(a, b))));

export const onDropDownOpen = (open) => {
  let mainContainer = document.querySelector(".main-container");
  if (open) {
    mainContainer.style.overflowY = "hidden";
  } else {
    mainContainer.style.overflowY = "auto";
  }
};

export const nameExistsValidation = (
  data,
  currentName,
  key,
  value,
  fieldName,
  isEditPage = false
) => {
  let duplicateData = { ...data?.[key].data };
  if (isEditPage) {
    let indexToBeDeleted = Object.values(duplicateData).findIndex(
      (item) => item.name === currentName
    );
    delete duplicateData[indexToBeDeleted];
  }
  if (
    Object.values(duplicateData).some(
      (item) => item.name.trim() === value?.trim()
    )
  ) {
    return Promise.reject(`${fieldName} name already exists!`);
  }
  return Promise.resolve();
};

export const getFormItemProps = (
  inputValue,
  name,
  form,
  formRules,
  isAnswer = false
) => {
  let textProps = {
    value: inputValue,
    onChange: (event) =>
      setQuestion(
        isAnswer ? "answers" : name,
        isAnswer ? form.getFieldValue("answers") : event.target.value,
        form
      ),
  };
  let formProps = {
    className: "textWidth",
    name,
  };
  if (formRules) {
    formProps["rules"] = formRules;
  }
  return { textProps, formProps };
};

export const getFormItemParamsForQuestion = (t, name, form, inputType) => {
  const isAnswer = typeof name !== "string";
  const fieldValue = isAnswer
    ? getAnswerByKey(name, form.getFieldValue("answers"))
    : form.getFieldValue(name);
  const formRules = addValidationRulesToForm(isAnswer, name, inputType);
  return getFormItemProps(t(fieldValue), name, form, formRules, isAnswer);
};

export const isSpecifiedPage = (pagePathname) => {
  return window.location.pathname.indexOf(pagePathname) !== -1;
};

export const performLogin = (setLogoutStatus, logout) => {
  setLogoutStatus(true);
  logout();
};

export const wholeNumberValidator = (_, value) => {
  if (value.toString().includes(".")) {
    return Promise.reject(
      "Please enter a whole number without a decimal point"
    );
  } else if (!Number.isInteger(Number(value))) {
    return Promise.reject("Please enter a whole number");
  } else {
    return Promise.resolve();
  }
};
export function reorderObjectKeys(inputObject, pageFlow, currentFrameworkType) {
  const orderedKeys = BREADCRUMB_KEYS[pageFlow];
  orderedKeys[0] = currentFrameworkType;
  const reorderedObject = {};
  orderedKeys.forEach((key) => {
    if (inputObject[key] !== undefined) {
      reorderedObject[key] = inputObject[key];
    }
  });

  return reorderedObject;
}

export const passwordValidator = (_, value) => {
  if (!value || /^\d{4}$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("The password must be a 4 digit number"));
};

export const invalidateQueriesAndShowNotification = (queryClient, message) => {
  queryClient.invalidateQueries("framework").then(() => {
    queryClient.invalidateQueries("frameworks").then(() => {
      openNotificationWithIcon({
        type: "success",
        message,
      });
    });
  });
};

export const getSelectedLanguage = (languageName, countryId, countries) => {
  const countryLanguages = getCountryLang(countryId, countries);
  return countryLanguages?.find((language) => language.name === languageName);
};
export const getAndSetCropsByLanguage = (
  selectedLanguage,
  crops,
  countryId,
  countries
) => {
  const selectedLanguageLocale = getSelectedLanguage(
    selectedLanguage,
    countryId,
    countries
  )?.locale;
  const CROPS_BY_LANGUAGE = getCropsByLanguage(crops, selectedLanguageLocale);
  return CROPS_BY_LANGUAGE;
};

function filterAvailableCountries(allCountries, selectedCountries) {
  return allCountries.filter((country) => {
    const selectedCountry =
      selectedCountries?.filter((s) => s?.country === country?.id) || [];
    return selectedCountry?.length === 0;
  });
}

export const getCountries = (selectedCountries, allCountries) => {
  let newCountries = allCountries;
  if (selectedCountries?.length > 0) {
    newCountries = filterAvailableCountries(allCountries, selectedCountries);
  }
  return newCountries;
};

export const getLanguages = (countryId, countries, selectedCountries) => {
  if (countries?.length) {
    const data = getFilteredCountryLang(
      countryId,
      countries,
      selectedCountries
    )?.map((countryLang) => countryLang.name);
    return data;
  }
};

export const getCountryLang = (countryId, countries) => {
  const countryDetails = getCountryDetails(countryId, countries);
  return countryDetails?.countriesLanguages?.map(
    (countryLang) => countryLang.language
  );
};
const getFilteredCountryLang = (countryId, countries, selectedCountries) => {
  const countryDetails = getCountryDetails(countryId, countries);
  const currentSelectedCountries = selectedCountries?.filter(
    (sc) => sc?.country === countryId
  );
  const selectedLanguages =
    currentSelectedCountries?.map((sc) => sc?.language) || [];
  const filteredLangauges = countryDetails?.countriesLanguages.filter(
    (cl) => !selectedLanguages?.includes(cl?.language?.name)
  );
  if (selectedLanguages.length) {
    return filteredLangauges?.map((fl) => fl?.language);
  }
  return filteredLangauges?.map((fl) => fl?.language);
};
export const getCountryDetails = (countryId, countries) => {
  return countries?.find((country) => country.id === countryId);
};

export const getCropsByLanguage = (
  crops,
  selectedLanguageLocale,
  displayLocale
) => {
  let cropDataToUpdate = structuredClone([...crops]);
  const cropsWithSeletedLanguageLocale = cropDataToUpdate?.filter((crop) => {
    if (crop.name[selectedLanguageLocale]) {
      crop.id = crop.baseCropId;
      crop.name = crop.name[displayLocale ?? selectedLanguageLocale];
      return crop;
    }
  });
  return cropsWithSeletedLanguageLocale;
};

export function cropHasAllLocales(crops, locales) {
  const updatedCrop = structuredClone([...crops]);
  return updatedCrop.filter((crop) => {
    const hasAllLocales = locales.every((locale) =>
      Object.keys(crop.name).some((key) => key === locale)
    );
    if (hasAllLocales) {
      crop.id = crop.baseCropId;
      crop.name = crop.name["en"];
      return crop;
    }
  });
}

export const generateCropYearsList = (
  nums,
  year = new Date().getFullYear()
) => {
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => `${start + i * step}`
    );
  return range(year - 1, year + nums, 1);
};

export const isSuperAdmin = (userRole) => {
  const superAdmin = USERROLE.SUPERADMIN;
  if (Array.isArray(superAdmin)) {
    return superAdmin.includes(userRole);
  } else {
    return userRole === superAdmin;
  }
};

export const getFrameworkDataset = (frameworkList) =>
  frameworkList?.frameworks.data.map((framework) => {
    const otherCountriesData =
      framework.frameworkDetails?.length > 1 ? framework.frameworkDetails : [];
    const otherCountries = otherCountriesData?.map(
      (item) => item?.country?.name
    );
    return {
      id: framework.id,
      country: framework.frameworkDetails[0]?.country?.name ?? "",
      otherCountries,
      partner: framework.frameworkPartner.find(
        (fp) => fp.partner.type === "umbrella"
      ).partner.name,
      name: framework.name ?? "",
      status: framework.status,
      version: framework.version,
    };
  }) ?? [];

export const organizeFrameworkByEntity = (
  entity,
  frameworkDataset,
  CreateFrameworkCardsRow
) => {
  const groupOfFrameworks = frameworkDataset.reduce((acc, framework) => {
    if (!acc[framework[entity]]) {
      acc[framework[entity]] = [];
    }
    acc[framework[entity]].push(framework);
    return acc;
  }, {});

  return Object.entries(groupOfFrameworks).map(
    ([header, frameworks], index) => ({
      key: index,
      header,
      content: CreateFrameworkCardsRow(frameworks),
    })
  );
};

export function getTranslationByLocale(translation, countryLanguage) {
  return translation?.find(
    (translatedQuestion) => translatedQuestion.locale === countryLanguage
  );
}

export const getAnswers = (translation, countryLanguage) => {
  const translatedLabel = getTranslationByLocale(translation, countryLanguage);
  return translatedLabel?.labels?.map((label) => {
    return {
      [`input`]: label?.["labelName"],
      [`soa_id`]: label?.["soa_id"],
      [`label_id`]: label?.["labelId"],
    };
  });
};
export const getCropsByLocale = (
  countryLangUnitsList,
  baseCropsCatalog,
  countries
) => {
  if (!countryLangUnitsList?.length) {
    return;
  }
  const supportedLanguages = countryLangUnitsList?.map((item) => {
    return { language: item.language, countryId: item.country };
  });
  const supportedLocales = supportedLanguages.map(
    ({ language, countryId }) =>
      getSelectedLanguage(language, countryId, countries)?.locale
  );
  const cropsForSelectedLocale = cropHasAllLocales(
    baseCropsCatalog,
    supportedLocales
  );
  return cropsForSelectedLocale;
};
export const getUnitId = (units, unitName) => {
  return units.find((unit) => unit.value === unitName)?.id;
};

export const getFrameworkPathname = (pathname) => {
  if (pathname.includes("frameworks-by-country")) {
    return "frameworks-by-country";
  } else if (pathname.includes("frameworks-by-partner")) {
    return "frameworks-by-partner";
  }
  return "frameworks-by-country";
};

export const findRemainingElementsFromArrays = (array1, array2) => {
  const set2 = new Set(array2);
  return array1?.filter((element) => !set2?.has(element));
};

export const getFrameworkStatus = (status, userRole) => {
  switch (status) {
    case StatusType.DRAFT:
      return "Request Publish Approval";
    case StatusType.IN_REVIEW:
      if (userRole === USERROLE.ADMIN) {
        return "Awaiting Approval";
      } else if (isSuperAdmin(userRole)) {
        return "Approve or Deny";
      } else {
        return "";
      }
    case StatusType.PUBLISHED:
      return "Published";
    default:
      return "";
  }
};
