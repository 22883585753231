import { useAuth } from "lib/auth/exports";
import React, { lazy, ReactNode, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loader } from "shared/layout/Loader";
import { getActiveModules } from "shared/utils/common-methods";
import NotFound from "./not-found";

export const AppRoutes = () => {
  const { routePaths } = useAuth();
  const routes: any = getActiveModules(routePaths);
  return (
    <Routes>
      {Object.keys(routes).map((route: any) => {
        return (
          <Route
            key={route}
            path={`/app/${route.toLowerCase()}/*`}
            element={
              <SuspenseWrapper>
                {React.createElement(routes[route].path)}
              </SuspenseWrapper>
            }
          />
        );
      })}
      <Route
        path="/app/frameworks-by-partner/*"
        element={
          <SuspenseWrapper>
            {React.createElement(
              lazy(() => import("modules/frameworks-by-partner"))
            )}
          </SuspenseWrapper>
        }
      />
      <Route
        path="/"
        element={<Navigate replace to="/app/frameworks-by-country" />}
      />
      <Route key="not-found" path="*" element={<NotFound />} />
    </Routes>
  );
};

interface SuspenseWrapperProps {
  children: ReactNode;
}

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ children }) => (
  <Suspense fallback={<Loader mask={true} message="Loading..." />}>
    {children}
  </Suspense>
);
